<template lang="pug">
t-window(v-bind="$attrs" v-on="$listeners" min-height="300px")
  v-window(v-model="tab" continuous)
    v-window-item(value="cart"): Cart(@route="dialogRoute")
    v-window-item(value="payment"): Payment(@route="dialogRoute")
    v-window-item(value="check"): Check(@route="dialogRoute")
    v-window-item(value="client"): Client(@route="dialogRoute")
</template>
<script>
export default {
  components: {
    Basket: () => import('T@/components/cart/Basket.vue'),
    Cart: () => import('T@/components/cart/Cart.vue'),
    Check: () => import('T@/components/cart/check/Check.vue'),
    Payment: () => import('T@/components/cart/payment/Payment.vue'),
    Client: () => import('T@/components/cart/client/Client.vue'),
  },
  data: () => ({
    tab: 'cart',
    item: 0,
  }),
  methods: {
    dialogRoute(route) {
      console.log('dialogRoute', route)
      this.tab = route
    },
  },
}
</script>
<style lang="stylus">

</style>
